header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header_socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 40rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 20rem);
    margin-top: 4rem;
    border-radius: 6rem;
    padding: 1.5rem;
    transform: rotate(5deg);
}

.me img{
    border-radius: 6rem 6rem 6rem 6rem;
    transform: rotate(-5deg);
}

.header_text h5{
    font-size: 1.5rem;
    color: var(--color-primary-variant);
}

.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px){
    header{
        height: 90vh;
    }
    
}

@media screen and (max-width: 600px){
    header{
        height: 100vh;
    }

    .header_socials{
        display: none;
    }

    .header_text h1{
        font-size: 2.5rem;
    }
    .header_text h5{
        font-size: 1rem;
    }

    .me{
        width: 20rem;
        height: 15rem;
        left: calc(50% - 10rem);
        border-radius: 3rem;
    }

    .me img{
        border-radius: 3rem;
    }
}
