.container.contact_container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 12%
}

.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option{
    background: var(--color-primary);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);

}

.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option:hover .contact_option_icon{
    color: var(--color-primary);
}

.contact_option:hover h4{
    color: var(--color-primary-variant);
    transition: var(--transition);
}

.contact_option:hover h5{
    color: var(--color-primary);
}

.contact_option:hover a{
    color: var(--color-primary-variant);
}

.contact_option_icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-bg);
    transition: var(--transition);
}

.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: var(--color-bg-variant);
    transition: var(--transition);
}

.contact_option a:hover{
    text-decoration: underline;
}

.contact_option h5{
    color: var(--color-bg);
    transition: var(--transition);
}

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-primary);
}

.contact_container .btn{
    background: transparent;
    color:var(--color-primary);
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.contact_container .btn:hover{
    background: var(--color-primary);
    color: var(--color-bg);
    border-color: transparent;
}

.contact_socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    right: 2rem;
    top: 2rem;
}

.contact_socials .contact_option_icon{
    font-size: 2rem;
    margin:auto;
    margin-top:1rem;
}

@media screen and (max-width: 1024px){
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 600px){
    .container.contact_container{
        width: var(--container-width-sm);

    }

}