body {
  height: 100%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  margin:0;
  height: 100%;
  scroll-behavior: smooth;
}

#root{
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
:root{

  --color-bg: #09445D;
  --color-bg-variant: #0E6D95;
  --color-primary: #F5F9E9;
  --color-primary-variant: #B8B8B8;
  --color-white: #fff;
  --color-light: #E8EBE4;
  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

.container{
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,h2,h3,h4,h5{
  font-weight: 500;
}

h1{
  font-size: 4rem;
  color: var(--color-primary);
}

h2{
  font-size: 2rem;
}

h5{
  font-size: 1rem;
}

section{
  margin-top: 8rem;
}

section > h2, section > h5{
  text-align: center;
  color: var(--color-light)
}

section > h2 {
  margin-bottom: 3rem;
  color: var(--color-primary);
}

.text-light{
  color: var(--color-light);
}

a{
  transition: var(--transition);
  color: var(--color-primary);
}

a:hover{
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  color: var(--color-primary);
  
}

.btn:hover{
  background: var(--color-primary);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary{
  background: var(--color-primary);
  color: var(--color-bg)
}

img{
  display: block;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);

  }

  section{
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container{
    width: var(--container-width-sm);

  }

  section > hw{
    margin-bottom: 2rem;
  }
}