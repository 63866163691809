.experience_container{
   /* display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;*/
}

.experience_container > div {
    background-color: var(--color-primary);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--tranistion);
}

.experience_container > div:hover{
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
}

.experience_container > div h3{
    text-align:center;
    margin-bottom: 2rem;
    color: black;
    transition: var(--tranistion);
}

.experience_container > div:hover h3{
    color: var(--color-primary-variant);
}

.experience_container > div:hover .experience_details > .icon{
    color: var(--color-primary);
}

.experience_container > div:hover .experience_details h4{
    color: var(--color-primary);
}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 2rem;
}

.experience_details{
    display:flex;
    gap: 1rem;
    margin-left:25%;
}

.experience_details h4{
    color: var(--color-bg);
    transition: var(--tranistion);
}

.experience_details > .icon{
    color: var(--color-bg);
    transition: var(--tranistion);
}

@media screen and (max-width: 1024px){
    .experience_container{
        grid-template-columns: 1fr;
    }

    .experience_container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content{
        padding: 1rem;
    }
}

@media screen and (max-width: 600px){

    .experience_container{
        gap: 1rem;
    }

    .experience_container > div{
        width: 100%;
        padding: 2rem 1rem;
    }

    .experience_content{
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
    }

    .experience_details{
        margin-left: 0;
    }

}